/**
 * Foundation for Sites by ZURB
 * Version 6.5.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

 .is-visible {
    display: block !important; }
  
  .is-hidden {
    display: none !important; }
  
  .hide {
    display: none !important; }
  
  .invisible {
    visibility: hidden; }
  
  @media screen and (max-width: 39.99875em) {
    .hide-for-small-only {
      display: none !important; } }
  
  @media screen and (max-width: 0em), screen and (min-width: 40em) {
    .show-for-small-only {
      display: none !important; } }
  
  @media print, screen and (min-width: 40em) {
    .hide-for-medium {
      display: none !important; } }
  
  @media screen and (max-width: 39.99875em) {
    .show-for-medium {
      display: none !important; } }
  
  @media screen and (min-width: 40em) and (max-width: 63.99875em) {
    .hide-for-medium-only {
      display: none !important; } }
  
  @media screen and (max-width: 39.99875em), screen and (min-width: 64em) {
    .show-for-medium-only {
      display: none !important; } }
  
  @media print, screen and (min-width: 64em) {
    .hide-for-large {
      display: none !important; } }
  
  @media screen and (max-width: 63.99875em) {
    .show-for-large {
      display: none !important; } }
  
  @media screen and (min-width: 64em) and (max-width: 74.99875em) {
    .hide-for-large-only {
      display: none !important; } }
  
  @media screen and (max-width: 63.99875em), screen and (min-width: 75em) {
    .show-for-large-only {
      display: none !important; } }
  
  .show-for-sr,
  .show-on-focus {
    position: absolute !important;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0; }
  
  .show-on-focus:active, .show-on-focus:focus {
    position: static !important;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal; }
  
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important; }
    @media screen and (orientation: landscape) {
      .show-for-landscape,
      .hide-for-portrait {
        display: block !important; } }
    @media screen and (orientation: portrait) {
      .show-for-landscape,
      .hide-for-portrait {
        display: none !important; } }
  
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important; }
    @media screen and (orientation: landscape) {
      .hide-for-landscape,
      .show-for-portrait {
        display: none !important; } }
    @media screen and (orientation: portrait) {
      .hide-for-landscape,
      .show-for-portrait {
        display: block !important; } }
  
  .row {
    max-width: 78.75rem;
    margin-right: auto;
    margin-left: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap; }
    .row .row {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; }
      @media print, screen and (min-width: 40em) {
        .row .row {
          margin-right: -0.9375rem;
          margin-left: -0.9375rem; } }
      @media print, screen and (min-width: 64em) {
        .row .row {
          margin-right: -0.9375rem;
          margin-left: -0.9375rem; } }
      .row .row.collapse {
        margin-right: 0;
        margin-left: 0; }
    .row.expanded {
      max-width: none; }
      .row.expanded .row {
        margin-right: auto;
        margin-left: auto; }
    .row:not(.expanded) .row {
      max-width: none; }
    .row.collapse > .column, .row.collapse > .columns {
      padding-right: 0;
      padding-left: 0; }
    .row.is-collapse-child,
    .row.collapse > .column > .row,
    .row.collapse > .columns > .row {
      margin-right: 0;
      margin-left: 0; }
  
  .column, .columns {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
    min-width: 0; }
  
  .column.row.row, .row.row.columns {
    float: none;
    display: block; }
  
  .row .column.row.row, .row .row.row.columns {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0; }
  
  .small-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  
  .small-offset-0 {
    margin-left: 0%; }
  
  .small-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  
  .small-offset-1 {
    margin-left: 8.33333%; }
  
  .small-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  
  .small-offset-2 {
    margin-left: 16.66667%; }
  
  .small-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  
  .small-offset-3 {
    margin-left: 25%; }
  
  .small-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  
  .small-offset-4 {
    margin-left: 33.33333%; }
  
  .small-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  
  .small-offset-5 {
    margin-left: 41.66667%; }
  
  .small-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  
  .small-offset-6 {
    margin-left: 50%; }
  
  .small-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  
  .small-offset-7 {
    margin-left: 58.33333%; }
  
  .small-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  
  .small-offset-8 {
    margin-left: 66.66667%; }
  
  .small-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  
  .small-offset-9 {
    margin-left: 75%; }
  
  .small-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  
  .small-offset-10 {
    margin-left: 83.33333%; }
  
  .small-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  
  .small-offset-11 {
    margin-left: 91.66667%; }
  
  .small-up-1 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .small-up-1 > .column, .small-up-1 > .columns {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      max-width: 100%; }
  
  .small-up-2 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .small-up-2 > .column, .small-up-2 > .columns {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%;
      max-width: 50%; }
  
  .small-up-3 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .small-up-3 > .column, .small-up-3 > .columns {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.33333%;
          -ms-flex: 0 0 33.33333%;
              flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  
  .small-up-4 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .small-up-4 > .column, .small-up-4 > .columns {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 25%;
          -ms-flex: 0 0 25%;
              flex: 0 0 25%;
      max-width: 25%; }
  
  .small-up-5 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .small-up-5 > .column, .small-up-5 > .columns {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 20%;
          -ms-flex: 0 0 20%;
              flex: 0 0 20%;
      max-width: 20%; }
  
  .small-up-6 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .small-up-6 > .column, .small-up-6 > .columns {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 16.66667%;
          -ms-flex: 0 0 16.66667%;
              flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  
  .small-up-7 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .small-up-7 > .column, .small-up-7 > .columns {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 14.28571%;
          -ms-flex: 0 0 14.28571%;
              flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  
  .small-up-8 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .small-up-8 > .column, .small-up-8 > .columns {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 12.5%;
          -ms-flex: 0 0 12.5%;
              flex: 0 0 12.5%;
      max-width: 12.5%; }
  
  .small-collapse > .column, .small-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  
  .small-uncollapse > .column, .small-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  
  @media print, screen and (min-width: 40em) {
    .medium-1 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 8.33333%;
          -ms-flex: 0 0 8.33333%;
              flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .medium-offset-0 {
      margin-left: 0%; }
    .medium-2 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 16.66667%;
          -ms-flex: 0 0 16.66667%;
              flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .medium-offset-1 {
      margin-left: 8.33333%; }
    .medium-3 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 25%;
          -ms-flex: 0 0 25%;
              flex: 0 0 25%;
      max-width: 25%; }
    .medium-offset-2 {
      margin-left: 16.66667%; }
    .medium-4 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.33333%;
          -ms-flex: 0 0 33.33333%;
              flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .medium-offset-3 {
      margin-left: 25%; }
    .medium-5 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 41.66667%;
          -ms-flex: 0 0 41.66667%;
              flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .medium-offset-4 {
      margin-left: 33.33333%; }
    .medium-6 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%;
      max-width: 50%; }
    .medium-offset-5 {
      margin-left: 41.66667%; }
    .medium-7 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 58.33333%;
          -ms-flex: 0 0 58.33333%;
              flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .medium-offset-6 {
      margin-left: 50%; }
    .medium-8 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 66.66667%;
          -ms-flex: 0 0 66.66667%;
              flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .medium-offset-7 {
      margin-left: 58.33333%; }
    .medium-9 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 75%;
          -ms-flex: 0 0 75%;
              flex: 0 0 75%;
      max-width: 75%; }
    .medium-offset-8 {
      margin-left: 66.66667%; }
    .medium-10 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 83.33333%;
          -ms-flex: 0 0 83.33333%;
              flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .medium-offset-9 {
      margin-left: 75%; }
    .medium-11 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 91.66667%;
          -ms-flex: 0 0 91.66667%;
              flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .medium-offset-10 {
      margin-left: 83.33333%; }
    .medium-12 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      max-width: 100%; }
    .medium-offset-11 {
      margin-left: 91.66667%; }
    .medium-up-1 {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .medium-up-1 > .column, .medium-up-1 > .columns {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
            -ms-flex: 0 0 100%;
                flex: 0 0 100%;
        max-width: 100%; }
    .medium-up-2 {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .medium-up-2 > .column, .medium-up-2 > .columns {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
        max-width: 50%; }
    .medium-up-3 {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .medium-up-3 > .column, .medium-up-3 > .columns {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
            -ms-flex: 0 0 33.33333%;
                flex: 0 0 33.33333%;
        max-width: 33.33333%; }
    .medium-up-4 {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .medium-up-4 > .column, .medium-up-4 > .columns {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
            -ms-flex: 0 0 25%;
                flex: 0 0 25%;
        max-width: 25%; }
    .medium-up-5 {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .medium-up-5 > .column, .medium-up-5 > .columns {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
            -ms-flex: 0 0 20%;
                flex: 0 0 20%;
        max-width: 20%; }
    .medium-up-6 {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .medium-up-6 > .column, .medium-up-6 > .columns {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
            -ms-flex: 0 0 16.66667%;
                flex: 0 0 16.66667%;
        max-width: 16.66667%; }
    .medium-up-7 {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .medium-up-7 > .column, .medium-up-7 > .columns {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 14.28571%;
            -ms-flex: 0 0 14.28571%;
                flex: 0 0 14.28571%;
        max-width: 14.28571%; }
    .medium-up-8 {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .medium-up-8 > .column, .medium-up-8 > .columns {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 12.5%;
            -ms-flex: 0 0 12.5%;
                flex: 0 0 12.5%;
        max-width: 12.5%; } }
  
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    .medium-expand {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px; } }
  
  .row.medium-unstack > .column, .row.medium-unstack > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%; }
    @media print, screen and (min-width: 40em) {
      .row.medium-unstack > .column, .row.medium-unstack > .columns {
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 0px;
            -ms-flex: 1 1 0px;
                flex: 1 1 0px; } }
  
  @media print, screen and (min-width: 40em) {
    .medium-collapse > .column, .medium-collapse > .columns {
      padding-right: 0;
      padding-left: 0; }
    .medium-uncollapse > .column, .medium-uncollapse > .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  
  @media print, screen and (min-width: 64em) {
    .large-1 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 8.33333%;
          -ms-flex: 0 0 8.33333%;
              flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .large-offset-0 {
      margin-left: 0%; }
    .large-2 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 16.66667%;
          -ms-flex: 0 0 16.66667%;
              flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .large-offset-1 {
      margin-left: 8.33333%; }
    .large-3 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 25%;
          -ms-flex: 0 0 25%;
              flex: 0 0 25%;
      max-width: 25%; }
    .large-offset-2 {
      margin-left: 16.66667%; }
    .large-4 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.33333%;
          -ms-flex: 0 0 33.33333%;
              flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .large-offset-3 {
      margin-left: 25%; }
    .large-5 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 41.66667%;
          -ms-flex: 0 0 41.66667%;
              flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .large-offset-4 {
      margin-left: 33.33333%; }
    .large-6 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%;
      max-width: 50%; }
    .large-offset-5 {
      margin-left: 41.66667%; }
    .large-7 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 58.33333%;
          -ms-flex: 0 0 58.33333%;
              flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .large-offset-6 {
      margin-left: 50%; }
    .large-8 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 66.66667%;
          -ms-flex: 0 0 66.66667%;
              flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .large-offset-7 {
      margin-left: 58.33333%; }
    .large-9 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 75%;
          -ms-flex: 0 0 75%;
              flex: 0 0 75%;
      max-width: 75%; }
    .large-offset-8 {
      margin-left: 66.66667%; }
    .large-10 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 83.33333%;
          -ms-flex: 0 0 83.33333%;
              flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .large-offset-9 {
      margin-left: 75%; }
    .large-11 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 91.66667%;
          -ms-flex: 0 0 91.66667%;
              flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .large-offset-10 {
      margin-left: 83.33333%; }
    .large-12 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      max-width: 100%; }
    .large-offset-11 {
      margin-left: 91.66667%; }
    .large-up-1 {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .large-up-1 > .column, .large-up-1 > .columns {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
            -ms-flex: 0 0 100%;
                flex: 0 0 100%;
        max-width: 100%; }
    .large-up-2 {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .large-up-2 > .column, .large-up-2 > .columns {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
        max-width: 50%; }
    .large-up-3 {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .large-up-3 > .column, .large-up-3 > .columns {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
            -ms-flex: 0 0 33.33333%;
                flex: 0 0 33.33333%;
        max-width: 33.33333%; }
    .large-up-4 {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .large-up-4 > .column, .large-up-4 > .columns {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
            -ms-flex: 0 0 25%;
                flex: 0 0 25%;
        max-width: 25%; }
    .large-up-5 {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .large-up-5 > .column, .large-up-5 > .columns {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
            -ms-flex: 0 0 20%;
                flex: 0 0 20%;
        max-width: 20%; }
    .large-up-6 {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .large-up-6 > .column, .large-up-6 > .columns {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
            -ms-flex: 0 0 16.66667%;
                flex: 0 0 16.66667%;
        max-width: 16.66667%; }
    .large-up-7 {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .large-up-7 > .column, .large-up-7 > .columns {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 14.28571%;
            -ms-flex: 0 0 14.28571%;
                flex: 0 0 14.28571%;
        max-width: 14.28571%; }
    .large-up-8 {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .large-up-8 > .column, .large-up-8 > .columns {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 12.5%;
            -ms-flex: 0 0 12.5%;
                flex: 0 0 12.5%;
        max-width: 12.5%; } }
  
  @media print, screen and (min-width: 64em) and (min-width: 64em) {
    .large-expand {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px; } }
  
  .row.large-unstack > .column, .row.large-unstack > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%; }
    @media print, screen and (min-width: 64em) {
      .row.large-unstack > .column, .row.large-unstack > .columns {
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 0px;
            -ms-flex: 1 1 0px;
                flex: 1 1 0px; } }
  
  @media print, screen and (min-width: 64em) {
    .large-collapse > .column, .large-collapse > .columns {
      padding-right: 0;
      padding-left: 0; }
    .large-uncollapse > .column, .large-uncollapse > .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  
  .shrink {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 100%; }
  
  .column-block {
    margin-bottom: 1.875rem; }
    .column-block > :last-child {
      margin-bottom: 0; }
  
  .align-left {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
  
  .align-right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
  
  .align-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
  
  .align-justify {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  
  .align-spaced {
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around; }
  
  .align-left.vertical.menu > li > a {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
  
  .align-right.vertical.menu > li > a {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
  
  .align-center.vertical.menu > li > a {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
  
  .align-top {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start; }
  
  .align-self-top {
    -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
            align-self: flex-start; }
  
  .align-bottom {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end; }
  
  .align-self-bottom {
    -webkit-align-self: flex-end;
        -ms-flex-item-align: end;
            align-self: flex-end; }
  
  .align-middle {
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
  
  .align-self-middle {
    -webkit-align-self: center;
        -ms-flex-item-align: center;
            align-self: center; }
  
  .align-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch; }
  
  .align-self-stretch {
    -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
            align-self: stretch; }
  
  .align-center-middle {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center; }
  
  .small-order-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  
  .small-order-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  
  .small-order-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  
  .small-order-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  
  .small-order-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  
  .small-order-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; }
  
  @media print, screen and (min-width: 40em) {
    .medium-order-1 {
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
          -ms-flex-order: 1;
              order: 1; }
    .medium-order-2 {
      -webkit-box-ordinal-group: 3;
      -webkit-order: 2;
          -ms-flex-order: 2;
              order: 2; }
    .medium-order-3 {
      -webkit-box-ordinal-group: 4;
      -webkit-order: 3;
          -ms-flex-order: 3;
              order: 3; }
    .medium-order-4 {
      -webkit-box-ordinal-group: 5;
      -webkit-order: 4;
          -ms-flex-order: 4;
              order: 4; }
    .medium-order-5 {
      -webkit-box-ordinal-group: 6;
      -webkit-order: 5;
          -ms-flex-order: 5;
              order: 5; }
    .medium-order-6 {
      -webkit-box-ordinal-group: 7;
      -webkit-order: 6;
          -ms-flex-order: 6;
              order: 6; } }
  
  @media print, screen and (min-width: 64em) {
    .large-order-1 {
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
          -ms-flex-order: 1;
              order: 1; }
    .large-order-2 {
      -webkit-box-ordinal-group: 3;
      -webkit-order: 2;
          -ms-flex-order: 2;
              order: 2; }
    .large-order-3 {
      -webkit-box-ordinal-group: 4;
      -webkit-order: 3;
          -ms-flex-order: 3;
              order: 3; }
    .large-order-4 {
      -webkit-box-ordinal-group: 5;
      -webkit-order: 4;
          -ms-flex-order: 4;
              order: 4; }
    .large-order-5 {
      -webkit-box-ordinal-group: 6;
      -webkit-order: 5;
          -ms-flex-order: 5;
              order: 5; }
    .large-order-6 {
      -webkit-box-ordinal-group: 7;
      -webkit-order: 6;
          -ms-flex-order: 6;
              order: 6; } }
  
  .flex-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  
  .flex-child-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto; }
  
  .flex-child-grow {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto; }
  
  .flex-child-shrink {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto; }
  
  .flex-dir-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row; }
  
  .flex-dir-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse; }
  
  .flex-dir-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column; }
  
  .flex-dir-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse; }
  
  @media print, screen and (min-width: 40em) {
    .medium-flex-container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; }
    .medium-flex-child-auto {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 auto;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto; }
    .medium-flex-child-grow {
      -webkit-box-flex: 1;
      -webkit-flex: 1 0 auto;
          -ms-flex: 1 0 auto;
              flex: 1 0 auto; }
    .medium-flex-child-shrink {
      -webkit-box-flex: 0;
      -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
              flex: 0 1 auto; }
    .medium-flex-dir-row {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; }
    .medium-flex-dir-row-reverse {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; }
    .medium-flex-dir-column {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; }
    .medium-flex-dir-column-reverse {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse; } }
  
  @media print, screen and (min-width: 64em) {
    .large-flex-container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; }
    .large-flex-child-auto {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 auto;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto; }
    .large-flex-child-grow {
      -webkit-box-flex: 1;
      -webkit-flex: 1 0 auto;
          -ms-flex: 1 0 auto;
              flex: 1 0 auto; }
    .large-flex-child-shrink {
      -webkit-box-flex: 0;
      -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
              flex: 0 1 auto; }
    .large-flex-dir-row {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; }
    .large-flex-dir-row-reverse {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; }
    .large-flex-dir-column {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; }
    .large-flex-dir-column-reverse {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse; } }
  
  .slide-in-down.mui-enter {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  
  .slide-in-down.mui-enter.mui-enter-active {
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0); }
  
  .slide-in-left.mui-enter {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  
  .slide-in-left.mui-enter.mui-enter-active {
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0); }
  
  .slide-in-up.mui-enter {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: translateY(100%);
        -ms-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  
  .slide-in-up.mui-enter.mui-enter-active {
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0); }
  
  .slide-in-right.mui-enter {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: translateX(100%);
        -ms-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  
  .slide-in-right.mui-enter.mui-enter-active {
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0); }
  
  .slide-out-down.mui-leave {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  
  .slide-out-down.mui-leave.mui-leave-active {
    -webkit-transform: translateY(100%);
        -ms-transform: translateY(100%);
            transform: translateY(100%); }
  
  .slide-out-right.mui-leave {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  
  .slide-out-right.mui-leave.mui-leave-active {
    -webkit-transform: translateX(100%);
        -ms-transform: translateX(100%);
            transform: translateX(100%); }
  
  .slide-out-up.mui-leave {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  
  .slide-out-up.mui-leave.mui-leave-active {
    -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
            transform: translateY(-100%); }
  
  .slide-out-left.mui-leave {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  
  .slide-out-left.mui-leave.mui-leave-active {
    -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
            transform: translateX(-100%); }
  
  .fade-in.mui-enter {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity; }
  
  .fade-in.mui-enter.mui-enter-active {
    opacity: 1; }
  
  .fade-out.mui-leave {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    opacity: 1;
    -webkit-transition-property: opacity;
    transition-property: opacity; }
  
  .fade-out.mui-leave.mui-leave-active {
    opacity: 0; }
  
  .hinge-in-from-top.mui-enter {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotateX(-90deg);
            transform: perspective(2000px) rotateX(-90deg);
    -webkit-transform-origin: top;
        -ms-transform-origin: top;
            transform-origin: top;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }
  
  .hinge-in-from-top.mui-enter.mui-enter-active {
    -webkit-transform: perspective(2000px) rotate(0deg);
            transform: perspective(2000px) rotate(0deg);
    opacity: 1; }
  
  .hinge-in-from-right.mui-enter {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotateY(-90deg);
            transform: perspective(2000px) rotateY(-90deg);
    -webkit-transform-origin: right;
        -ms-transform-origin: right;
            transform-origin: right;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }
  
  .hinge-in-from-right.mui-enter.mui-enter-active {
    -webkit-transform: perspective(2000px) rotate(0deg);
            transform: perspective(2000px) rotate(0deg);
    opacity: 1; }
  
  .hinge-in-from-bottom.mui-enter {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotateX(90deg);
            transform: perspective(2000px) rotateX(90deg);
    -webkit-transform-origin: bottom;
        -ms-transform-origin: bottom;
            transform-origin: bottom;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }
  
  .hinge-in-from-bottom.mui-enter.mui-enter-active {
    -webkit-transform: perspective(2000px) rotate(0deg);
            transform: perspective(2000px) rotate(0deg);
    opacity: 1; }
  
  .hinge-in-from-left.mui-enter {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotateY(90deg);
            transform: perspective(2000px) rotateY(90deg);
    -webkit-transform-origin: left;
        -ms-transform-origin: left;
            transform-origin: left;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }
  
  .hinge-in-from-left.mui-enter.mui-enter-active {
    -webkit-transform: perspective(2000px) rotate(0deg);
            transform: perspective(2000px) rotate(0deg);
    opacity: 1; }
  
  .hinge-in-from-middle-x.mui-enter {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotateX(-90deg);
            transform: perspective(2000px) rotateX(-90deg);
    -webkit-transform-origin: center;
        -ms-transform-origin: center;
            transform-origin: center;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }
  
  .hinge-in-from-middle-x.mui-enter.mui-enter-active {
    -webkit-transform: perspective(2000px) rotate(0deg);
            transform: perspective(2000px) rotate(0deg);
    opacity: 1; }
  
  .hinge-in-from-middle-y.mui-enter {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotateY(-90deg);
            transform: perspective(2000px) rotateY(-90deg);
    -webkit-transform-origin: center;
        -ms-transform-origin: center;
            transform-origin: center;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }
  
  .hinge-in-from-middle-y.mui-enter.mui-enter-active {
    -webkit-transform: perspective(2000px) rotate(0deg);
            transform: perspective(2000px) rotate(0deg);
    opacity: 1; }
  
  .hinge-out-from-top.mui-leave {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotate(0deg);
            transform: perspective(2000px) rotate(0deg);
    -webkit-transform-origin: top;
        -ms-transform-origin: top;
            transform-origin: top;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }
  
  .hinge-out-from-top.mui-leave.mui-leave-active {
    -webkit-transform: perspective(2000px) rotateX(90deg);
            transform: perspective(2000px) rotateX(90deg);
    opacity: 0; }
  
  .hinge-out-from-right.mui-leave {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotate(0deg);
            transform: perspective(2000px) rotate(0deg);
    -webkit-transform-origin: right;
        -ms-transform-origin: right;
            transform-origin: right;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }
  
  .hinge-out-from-right.mui-leave.mui-leave-active {
    -webkit-transform: perspective(2000px) rotateY(90deg);
            transform: perspective(2000px) rotateY(90deg);
    opacity: 0; }
  
  .hinge-out-from-bottom.mui-leave {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotate(0deg);
            transform: perspective(2000px) rotate(0deg);
    -webkit-transform-origin: bottom;
        -ms-transform-origin: bottom;
            transform-origin: bottom;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }
  
  .hinge-out-from-bottom.mui-leave.mui-leave-active {
    -webkit-transform: perspective(2000px) rotateX(-90deg);
            transform: perspective(2000px) rotateX(-90deg);
    opacity: 0; }
  
  .hinge-out-from-left.mui-leave {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotate(0deg);
            transform: perspective(2000px) rotate(0deg);
    -webkit-transform-origin: left;
        -ms-transform-origin: left;
            transform-origin: left;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }
  
  .hinge-out-from-left.mui-leave.mui-leave-active {
    -webkit-transform: perspective(2000px) rotateY(-90deg);
            transform: perspective(2000px) rotateY(-90deg);
    opacity: 0; }
  
  .hinge-out-from-middle-x.mui-leave {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotate(0deg);
            transform: perspective(2000px) rotate(0deg);
    -webkit-transform-origin: center;
        -ms-transform-origin: center;
            transform-origin: center;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }
  
  .hinge-out-from-middle-x.mui-leave.mui-leave-active {
    -webkit-transform: perspective(2000px) rotateX(90deg);
            transform: perspective(2000px) rotateX(90deg);
    opacity: 0; }
  
  .hinge-out-from-middle-y.mui-leave {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: perspective(2000px) rotate(0deg);
            transform: perspective(2000px) rotate(0deg);
    -webkit-transform-origin: center;
        -ms-transform-origin: center;
            transform-origin: center;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }
  
  .hinge-out-from-middle-y.mui-leave.mui-leave-active {
    -webkit-transform: perspective(2000px) rotateY(90deg);
            transform: perspective(2000px) rotateY(90deg);
    opacity: 0; }
  
  .scale-in-up.mui-enter {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }
  
  .scale-in-up.mui-enter.mui-enter-active {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  
  .scale-in-down.mui-enter {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: scale(1.5);
        -ms-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }
  
  .scale-in-down.mui-enter.mui-enter-active {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  
  .scale-out-up.mui-leave {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }
  
  .scale-out-up.mui-leave.mui-leave-active {
    -webkit-transform: scale(1.5);
        -ms-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0; }
  
  .scale-out-down.mui-leave {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }
  
  .scale-out-down.mui-leave.mui-leave-active {
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0; }
  
  .spin-in.mui-enter {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: rotate(-0.75turn);
        -ms-transform: rotate(-0.75turn);
            transform: rotate(-0.75turn);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }
  
  .spin-in.mui-enter.mui-enter-active {
    -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
    opacity: 1; }
  
  .spin-out.mui-leave {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }
  
  .spin-out.mui-leave.mui-leave-active {
    -webkit-transform: rotate(0.75turn);
        -ms-transform: rotate(0.75turn);
            transform: rotate(0.75turn);
    opacity: 0; }
  
  .spin-in-ccw.mui-enter {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: rotate(0.75turn);
        -ms-transform: rotate(0.75turn);
            transform: rotate(0.75turn);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 0; }
  
  .spin-in-ccw.mui-enter.mui-enter-active {
    -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
    opacity: 1; }
  
  .spin-out-ccw.mui-leave {
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    opacity: 1; }
  
  .spin-out-ccw.mui-leave.mui-leave-active {
    -webkit-transform: rotate(-0.75turn);
        -ms-transform: rotate(-0.75turn);
            transform: rotate(-0.75turn);
    opacity: 0; }
  
  .slow {
    -webkit-transition-duration: 750ms !important;
            transition-duration: 750ms !important; }
  
  .fast {
    -webkit-transition-duration: 250ms !important;
            transition-duration: 250ms !important; }
  
  .linear {
    -webkit-transition-timing-function: linear !important;
            transition-timing-function: linear !important; }
  
  .ease {
    -webkit-transition-timing-function: ease !important;
            transition-timing-function: ease !important; }
  
  .ease-in {
    -webkit-transition-timing-function: ease-in !important;
            transition-timing-function: ease-in !important; }
  
  .ease-out {
    -webkit-transition-timing-function: ease-out !important;
            transition-timing-function: ease-out !important; }
  
  .ease-in-out {
    -webkit-transition-timing-function: ease-in-out !important;
            transition-timing-function: ease-in-out !important; }
  
  .bounce-in {
    -webkit-transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
            transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }
  
  .bounce-out {
    -webkit-transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
            transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }
  
  .bounce-in-out {
    -webkit-transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
            transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }
  
  .short-delay {
    -webkit-transition-delay: 300ms !important;
            transition-delay: 300ms !important; }
  
  .long-delay {
    -webkit-transition-delay: 700ms !important;
            transition-delay: 700ms !important; }
  
  .shake {
    -webkit-animation-name: shake-7;
            animation-name: shake-7; }
  
  @-webkit-keyframes shake-7 {
    0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
      -webkit-transform: translateX(7%);
              transform: translateX(7%); }
    5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
      -webkit-transform: translateX(-7%);
              transform: translateX(-7%); } }
  
  @keyframes shake-7 {
    0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
      -webkit-transform: translateX(7%);
              transform: translateX(7%); }
    5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
      -webkit-transform: translateX(-7%);
              transform: translateX(-7%); } }
  
  .spin-cw {
    -webkit-animation-name: spin-cw-1turn;
            animation-name: spin-cw-1turn; }
  
  @-webkit-keyframes spin-cw-1turn {
    0% {
      -webkit-transform: rotate(-1turn);
              transform: rotate(-1turn); }
    100% {
      -webkit-transform: rotate(0);
              transform: rotate(0); } }
  
  @keyframes spin-cw-1turn {
    0% {
      -webkit-transform: rotate(-1turn);
              transform: rotate(-1turn); }
    100% {
      -webkit-transform: rotate(0);
              transform: rotate(0); } }
  
  .spin-ccw {
    -webkit-animation-name: spin-ccw-1turn;
            animation-name: spin-ccw-1turn; }
  
  @-webkit-keyframes spin-ccw-1turn {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0); }
    100% {
      -webkit-transform: rotate(-1turn);
              transform: rotate(-1turn); } }
  
  @keyframes spin-ccw-1turn {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0); }
    100% {
      -webkit-transform: rotate(-1turn);
              transform: rotate(-1turn); } }
  
  .wiggle {
    -webkit-animation-name: wiggle-7deg;
            animation-name: wiggle-7deg; }
  
  @-webkit-keyframes wiggle-7deg {
    40%, 50%, 60% {
      -webkit-transform: rotate(7deg);
              transform: rotate(7deg); }
    35%, 45%, 55%, 65% {
      -webkit-transform: rotate(-7deg);
              transform: rotate(-7deg); }
    0%, 30%, 70%, 100% {
      -webkit-transform: rotate(0);
              transform: rotate(0); } }
  
  @keyframes wiggle-7deg {
    40%, 50%, 60% {
      -webkit-transform: rotate(7deg);
              transform: rotate(7deg); }
    35%, 45%, 55%, 65% {
      -webkit-transform: rotate(-7deg);
              transform: rotate(-7deg); }
    0%, 30%, 70%, 100% {
      -webkit-transform: rotate(0);
              transform: rotate(0); } }
  
  .shake,
  .spin-cw,
  .spin-ccw,
  .wiggle {
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms; }
  
  .infinite {
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite; }
  
  .slow {
    -webkit-animation-duration: 750ms !important;
            animation-duration: 750ms !important; }
  
  .fast {
    -webkit-animation-duration: 250ms !important;
            animation-duration: 250ms !important; }
  
  .linear {
    -webkit-animation-timing-function: linear !important;
            animation-timing-function: linear !important; }
  
  .ease {
    -webkit-animation-timing-function: ease !important;
            animation-timing-function: ease !important; }
  
  .ease-in {
    -webkit-animation-timing-function: ease-in !important;
            animation-timing-function: ease-in !important; }
  
  .ease-out {
    -webkit-animation-timing-function: ease-out !important;
            animation-timing-function: ease-out !important; }
  
  .ease-in-out {
    -webkit-animation-timing-function: ease-in-out !important;
            animation-timing-function: ease-in-out !important; }
  
  .bounce-in {
    -webkit-animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
            animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }
  
  .bounce-out {
    -webkit-animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
            animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }
  
  .bounce-in-out {
    -webkit-animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
            animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }
  
  .short-delay {
    -webkit-animation-delay: 300ms !important;
            animation-delay: 300ms !important; }
  
  .long-delay {
    -webkit-animation-delay: 700ms !important;
            animation-delay: 700ms !important; }
  