.title {
  margin-bottom: -200px;
}

.glow {
  font-size: 80px;
  color: #fff;
  text-align: center;
  text-shadow: 0 0 10px rgb(148, 148, 148), 0 0 20px;

  &.top {
    margin-top: 214px;
  }
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px rgb(182, 182, 182), 0 0 20px rgb(182, 182, 182), 0 0 30px #676767, 0 0 40px #676767, 0 0 20px #676767;
  }

  to {
    text-shadow: 0 0 20px rgb(182, 182, 182), 0 0 30px #a7a7a7, 0 0 40px #a7a7a7, 0 0 50px #a7a7a7, 0 0 20px #a7a7a7;
  }
}

.top-bg {
  background-size: 1468px auto;
  background-position: top center;
  background-repeat: no-repeat;
  background-image: url('./../theme/images/top-bg.jpg');
  min-height: 1468px;
  margin-top: -50px;
  padding-top: 550px;
}

.bottom-bg {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-image: url('./../theme/images/bottom-bg.jpg');
  max-width: 100% !important;
  min-height: 1200px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #111;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.circle {
  position: relative;
  height: 500px;
  width: 500px;
  filter: url(#wave);
  animation: rotate 40s linear infinite;

  &::before {
    content: "";
    position: absolute;
    top: 80px;
    left: 80px;
    right: 80px;
    bottom: 80px;
    border: 16px solid #fff;
    border-radius: 50%;
    filter: url(#wave) blur(10px);
    animation: anim 40s linear infinite;
  }
}

@keyframes anim {
  0% {
    box-shadow: 0 0 100px #fff, inset 0 0 100px #fff;
  }

  20% {
    box-shadow: 0 0 100px rgb(182, 182, 182), inset 0 0 100px rgb(182, 182, 182);
  }

  40% {
    box-shadow: 0 0 100px #fff, inset 0 0 100px #fff;
  }

  60% {
    box-shadow: 0 0 100px rgb(182, 182, 182), inset 0 0 100px rgb(182, 182, 182);
  }

  80% {
    box-shadow: 0 0 100px #fff, inset 0 0 100px #fff;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

svg {
  // display: none;
}

.o {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  width: 500px;
  height: 500px;
}

video {
  display: block;
  width: calc(100% - 1.5rem);
  height: auto;
  margin: 2rem auto;
}